<template>
  <div>
    <!-- 顶部信息 -->
    <div class="headerbox">
      <el-row :gutter="20">
        <el-col :span="8">
          <img :src="talentData.image" style="width: 100%;height: 350px;border-radius: 20px;" @error="itemError(talentData)"/>
        </el-col>
        <el-col :span="16">
          <el-row style="text-align: left;">
            <el-col :span="24">
              <span class="spantitle">{{talentData.name}}</span>
            </el-col>
            <el-col :span="24" style="margin-top: 25px;">
              <span v-if="talentData.isStart==0" class="spanstatus1">可预约</span>
              <span v-if="talentData.isStart==1" class="spanstatus2">可报名</span>
              <span class="spanslast">上次开课：{{talentData.previous}}</span>
            </el-col>

            <el-col :span="24" style="margin-top: 200px;">
              <span class="pteacher">联系人:{{talentData.teacher}}</span>
            </el-col>
            <el-col :span="24" style="margin-top: 25px;">
              <span class="pteacher">参考费用：</span><span class="spanpay" style="font-size: 14px;">¥</span><span class="spanpay">{{talentData.fee}}</span>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <div class="contentbox">
      <el-row>
        <el-col :span="12"><p style="font-family: 'PingFang SC';font-style: normal;font-weight: 500;font-size: 20px;text-align: left;">学员信息<span style="margin-left: 20px;font-weight: 200;font-size: 14px;">共计{{tableData.length}}人</span></p></el-col>
        <el-col :span="12" style="text-align: right;"><el-button type="text" style="margin-right: 10px;" icon="el-icon-plus" @click="handleAdd()">新增学员</el-button></el-col>
      </el-row>
      <el-table :data="tableData" stripe style="width: 100%;">
        <el-table-column label="姓名" prop="name">
          <template slot-scope="scope">
            <el-input v-if="scope.row.check" v-model="scope.row.name"></el-input>
            <span v-else>{{scope.row.name}}</span>
          </template>
        </el-table-column>
        <el-table-column label="电话" prop="phone">
          <template slot-scope="scope">
            <el-input v-if="scope.row.check" v-model="scope.row.phone"></el-input>
            <span v-else>{{scope.row.phone}}</span>
          </template>
        </el-table-column>
        <el-table-column label="邮箱" prop="email">
          <template slot-scope="scope">
            <el-input v-if="scope.row.check" v-model="scope.row.email"></el-input>
            <span v-else>{{scope.row.email}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200px">
          <template slot-scope="scope">
            <el-button type="text" @click="handleSure(scope)">确认</el-button>
            <el-button type="text" @click="handleEdit(scope)">编辑</el-button>
            <el-button type="text" @click="handleDel(scope)">删除</el-button>
          </template>

        </el-table-column>
      </el-table>
    </div>
    <div class="contentbox">
      <el-row>
        <el-col :span="24"><p style="font-family: 'PingFang SC';font-style: normal;font-weight: 500;font-size: 20px;text-align: left;">联系人</p></el-col>
        <el-col :span="6">
          <el-row>
            <el-col :span="8">
              <el-button type="text" style="color: black;">姓名</el-button>
            </el-col>
            <el-col :span="16">
              <el-input v-model="userData.contact" label="姓名" placeholder="请输入"></el-input>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="6">
          <el-row>
            <el-col :span="8">
              <el-button type="text" style="color: black;">手机号</el-button>
            </el-col>
            <el-col :span="16">
              <el-input v-model="userData.contactPhone" label="手机号" placeholder="请输入"></el-input>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="6">
          <el-row>
            <el-col :span="8">
              <el-button type="text" style="color: black;">手机验证码</el-button>
            </el-col>
            <el-col :span="16">
              <el-input v-model="userData.smscode" label="手机验证码" placeholder="请输入"></el-input>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="6" style="text-align: left;">
          <el-button type="text" style="margin-left: 10px;" @click="getSmscode()" :disabled="state.smsSendBtn" v-text="!state.smsSendBtn && '获取验证码' || (state.time+' s')"></el-button>
        </el-col>
      </el-row>
    </div>
    <div class="contbox">
      <el-button @click="handleSubmit()" style="width: 120px;background-color: #00CF97;color: #FFFFFF;">提交</el-button>
    </div>
    <el-dialog :visible.sync="visibleModal" width="360px" title="完成拼图验证" :footer="null" :dialogStyle="dialogStyle" @cancel="handleCancel()">
      <template>
        <div style="position: absolute;left: 65%;top:18px">
          <a @click="reloadImg()">
            <el-icon type="reload" theme="outlined"></el-icon>换一张
          </a>
        </div>
      </template>

      <div class="slider" ref="slider">
        <div class="content">
          <div class="bg-img-div">
            <img ref="bg_img" id="bg-img" :src="img_p1" @load="loadBgImg" alt />
          </div>
          <div class="slider-img-div" id="slider-img-div" :style="transform1">
            <img ref="slider_img" id="slider-img" @load="loadImg" :src="img_p2" alt />
          </div>
        </div>
        <div class="slider-move">
          <div class="slider-move-track">拖动滑块完成拼图</div>
          <div class="slider-move-btn" id="slider-move-btn" :style="transform2" @touchstart="down" @mousedown="down"></div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { baseURL } from "@/utils/request/apis";
import defaultImage from "@/assets/images/home/default_companyLogo.png";
export default {
  name: "TPTalentAddRecord",
  data() {
    return {
      //Record表id
      id:'',
      activeName:"first",
      url:{
        getLzCourseUrl:"/lz_course/lzCourse/getById",
        getLzCourseRecordUrl:"/lz_course_record/lzCourseRecord/queryById",
        addLzCourseUserUrl:"/lz_course_user/lzCourseUser/addBatch",
        checkSms:"/lz_course_record/lzCourseRecord/checkSms",
      },
      userData:{
        contact:'',
        contactPhone:'',
        smscode:''
      },
      talentData:{},
      tableData:[],
      recordData:{},
      //行为验证码
      currentCaptchaConfig: {},
      currentCaptchaId: null,
      img_p1: '',
      img_p2: '',
      transform1: 'transform:translate(0px, 0px)',
      transform2: 'transform:translate(0px, 0px)',
      dialogStyle: { top: "40%", right: "-20%" },
      visibleModal: false,
      //手机号登录用
      state: {
        time: 60,
        smsSendBtn: false,
      },
      defaultImage
    }
  },
  methods: {
    itemError(item){
      item.image = this.defaultImage;
    },
    // 获取基本信息
    getTalentData(){
      this.$http.get(baseURL+this.url.getLzCourseUrl, { id: this.recordData.courseId}).then((res) => {
        this.talentData = res.result;
        this.talentData.image = baseURL + '/' + this.talentData.image;
      });
      this.tableData = [
        {'name':"",'phone':"",'email':"",'check':true}
      ];
    },
    // 获取基本信息
    getRecordData(){
      this.$http.get(baseURL+this.url.getLzCourseRecordUrl, { id: this.id}).then((res) => {
        if(res.success){
          this.recordData = res.result;
          if(this.recordData.id >0){
            this.getTalentData();
          }
        }
      });
    },
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    handleAdd(){
      for(let i = 0;i<this.tableData.length;i++){
        if(this.tableData[i].name == "" || this.tableData[i].name == null){
          this.$message("请完善信息后添加！");
          return;
        }
        if(this.tableData[i].phone == "" || this.tableData[i].phone == null){
          this.$message("请完善信息后添加！");
          return;
        }
        if(!new RegExp(/^1[3456789]\d{9}$/).test(this.tableData[i].phone)){
          this.$message("请填写正确手机号后添加！");
          return;
        }
      }
      let empty = {'name':"",'phone':"",'email':"",'check':true};
      this.tableData.push(empty);
    },
    handleSure(scope){
      if(this.tableData[scope.$index].check){
        if(this.tableData[scope.$index].name == "" || this.tableData[scope.$index].name == null){
          this.$message("请完善信息后确认！");
          return;
        }
        if(this.tableData[scope.$index].phone == "" || this.tableData[scope.$index].phone == null){
          this.$message("请完善信息后确认！");
          return;
        }
        if(!new RegExp(/^1[3456789]\d{9}$/).test(this.tableData[scope.$index].phone)){
          this.$message("请填写正确手机号后确认！");
          return;
        }
        this.tableData[scope.$index].check = false;
      }
    },
    handleEdit(scope){
      this.tableData[scope.$index].check = true;
    },
    handleDel(scope){
      this.tableData.splice(scope.$index,1);
    },
    handleSubmit(){
      if(this.userData.contactPhone == "" || this.userData.contactPhone == null){
        this.$message("请填写联系人电话后提交！");
        return;
      }
      if(this.userData.smscode == "" || this.userData.smscode == null){
        this.$message("请填写短信验证码后提交！");
        return;
      }
      this.$http.post(baseURL+this.url.checkSms, {'mobile':this.userData.contactPhone,'userType':2,'smscode':this.userData.smscode,'smsmode':5}).then((res) => {
        if(res.success){
          this.postUser();
        }else{
          this.$message(res.message);
          return;
        }
      });
    },
    postUser(){
      for(let i = 0;i<this.tableData.length;i++){
        if(this.tableData[i].name == "" || this.tableData[i].name == null){
          this.$message("请完善信息后提交！");
          return;
        }
        if(this.tableData[i].phone == "" || this.tableData[i].phone == null){
          this.$message("请完善信息后提交！");
          return;
        }
        if(!new RegExp(/^1[3456789]\d{9}$/).test(this.tableData[i].phone)){
          this.$message("请填写正确手机号后提交！");
          return;
        }
      }
      if(this.userData.contact == "" || this.userData.contact == null){
        this.$message("请填写联系人后提交！");
        return;
      }
      let userList = [];
      for(let i=0;i < this.tableData.length;i++){
        let userData = {
          courseId:this.talentData.id,
          courseName:this.talentData.name,
          courseRecordId:this.recordData.id,
          userName:this.tableData[i].name,
          userPhone:this.tableData[i].phone,
          userEmail:this.tableData[i].email,
          signType:this.talentData.isStart,
          contact:this.userData.contact,
          contactPhone:this.userData.contactPhone,
        }
        userList.push(userData);
      }
      this.$http.post(baseURL+this.url.addLzCourseUserUrl, userList).then((res) => {
        if(res.success){
          if(this.talentData.isStart == "1"){
            const url = this.$router.push({ name: 'TPEnrollResult' });
          }else{
            const url = this.$router.push({ name: 'TPSubmitResult' });
          }
        }else{
          this.$message(res.message);
          return;
        }
      });
    },
    //图像验证码
    /**
     * 验证字段
     * @param arr
     * @param callback
     */
    validateFields(arr, callback) {
      let promiseArray = []
      for (let item of arr) {
        let p = new Promise((resolve, reject) => {
          this.$refs['form'].validateField(item, (err) => {
            if (!err) {
              resolve();
            } else {
              reject(err);
            }
          })
        });
        promiseArray.push(p)
      }
      Promise.all(promiseArray).then(() => {
        callback()
      }).catch(err => {
        callback(err)
      })
    },
    // 校验手机号
    validateMobile(rule, value, callback) {
      if (!value || new RegExp(/^1[3456789]\d{9}$/).test(value)) {
        callback();
      } else {
        callback("您的手机号码格式不正确!");
      }
    },
    cmsFailed(err) {
      this.$message({
        message: err,
        type: 'warning'
      })
    },
    cmsSuccess(message) {
      this.$message({
        message: "获取短信验证码成功",
        type: 'warning'
      })
    },
    valid(captchaConfig) {
      let data = {
        bgImageWidth: JSON.parse(JSON.stringify(captchaConfig.bgImageWidth)),
        bgImageHeight: captchaConfig.bgImageHeight,
        sliderImageWidth: captchaConfig.sliderImageWidth,
        sliderImageHeight: captchaConfig.sliderImageHeight,
        startSlidingTime: this.formatDateTime(captchaConfig.startTime),
        entSlidingTime: this.formatDateTime(captchaConfig.stopTime),
        trackList: JSON.parse(JSON.stringify(captchaConfig.trackArr))
      };
      let that = this;
      let _res = ''
      this.$http.post(baseURL + "/getSmsCode?id=" + this.currentCaptchaId + '&mobile=' + that.userData.contactPhone + '&smsmode=5&userType=2', data).then((res) => {
        _res = res
        if (res.success) {
          this.visibleModal = false;
          that.state.smsSendBtn = true;
          let interval = window.setInterval(() => {
            if (that.state.time-- <= 0) {
              that.state.time = 60;
              that.state.smsSendBtn = false;
              window.clearInterval(interval);
            }
          }, 1000);
        } else {
          // setTimeout(hide, 0);
        }
        this.refreshCaptcha();
      }).finally(()=>{
        if(_res.success) that.cmsSuccess(_res.message)
        else  that.cmsFailed(_res.message);
      })
    },
    initCaptcha() {
      this.$http.get(baseURL + `/gen`).then((data) => {
        if (data.success) {
          this.reset();
          this.currentCaptchaId = data.result.id;
          this.img_p1 = data.result.captcha.backgroundImage;
          this.img_p2 = data.result.captcha.sliderImage;
        } else {
          this.$message.warning("后台服务器异常，请稍后再试！");
        }
      });
    },
    refreshCaptcha() {
      this.$http.get(baseURL + `/gen`).then((data) => {
        if (data.success) {
          this.reset();
          this.currentCaptchaId = data.result.id;
          this.img_p1 = data.result.captcha.backgroundImage;
          this.img_p2 = data.result.captcha.sliderImage;

          let width1 = this.$refs['bg_img'].offsetWidth;
          let height1 = this.$refs['bg_img'].offsetHeight;
          let width2 = this.$refs['slider_img'].offsetWidth;
          let height2 = this.$refs['slider_img'].offsetHeight;
          this.initConfig(width1, height1, width2, height2, 206);

        } else {
          this.$message.warning("后台服务器异常，请稍后再试！");
        }
      });
    },
    doDown() {
      // $("#slider-move-btn").css("background-position", "-5px 31.0092%")
    },
    doMove(currentCaptchaConfig) {
      const moveX = currentCaptchaConfig.moveX;
      this.transform1 = "transform:translate(" + moveX + "px, 0px)"
      this.transform2 = "transform:translate(" + moveX + "px, 0px);background-position: -5px 55.79625%;"
    },
    reset() {
      this.transform1 = "transform:translate(0px, 0px)"
      this.transform2 = "transform:translate(0px, 0px);background-position: -5px 55.79625%;"
      this.currentCaptchaId = null;
    },

    formatDateTime(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      h = h < 10 ? "0" + h : h;
      var minute = date.getMinutes();
      minute = minute < 10 ? "0" + minute : minute;
      var second = date.getSeconds();
      second = second < 10 ? "0" + second : second;
      return y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
    },

    initConfig(bgImageWidth, bgImageHeight, sliderImageWidth, sliderImageHeight, end) {
      this.currentCaptchaConfig = {
        startTime: new Date(),
        trackArr: [],
        movePercent: 0,
        bgImageWidth,
        bgImageHeight,
        sliderImageWidth,
        sliderImageHeight,
        end
      }
    },
    down(event) {
      let targetTouches = event.originalEvent ? event.originalEvent.targetTouches : event.targetTouches;
      let startX = event.pageX;
      let startY = event.pageY;
      if (startX === undefined) {
        startX = Math.round(targetTouches[0].pageX);
        startY = Math.round(targetTouches[0].pageY);
      }
      this.currentCaptchaConfig.startX = startX;
      this.currentCaptchaConfig.startY = startY;

      const pageX = this.currentCaptchaConfig.startX;
      const pageY = this.currentCaptchaConfig.startY;
      const startTime = this.currentCaptchaConfig.startTime;
      const trackArr = this.currentCaptchaConfig.trackArr;
      trackArr.push({
        x: pageX - startX,
        y: pageY - startY,
        type: "down",
        t: (new Date().getTime() - startTime.getTime())
      });
      window.addEventListener("mousemove", this.move);
      window.addEventListener("mouseup", this.up);
      // 手机端
      window.addEventListener("touchmove", this.move, false);
      window.addEventListener("touchend", this.up, false);
      this.doDown(this.currentCaptchaConfig);
    },
    move(event) {
      if (event instanceof TouchEvent) {
        event = event.touches[0];
      }
      let pageX = Math.round(event.pageX);
      let pageY = Math.round(event.pageY);
      const startX = this.currentCaptchaConfig.startX;
      const startY = this.currentCaptchaConfig.startY;
      const startTime = this.currentCaptchaConfig.startTime;
      const end = this.currentCaptchaConfig.end;
      const bgImageWidth = this.currentCaptchaConfig.bgImageWidth;
      const trackArr = this.currentCaptchaConfig.trackArr;
      let moveX = pageX - startX;
      const track = {
        x: pageX - startX,
        y: pageY - startY,
        type: "move",
        t: (new Date().getTime() - startTime.getTime())
      };
      trackArr.push(track);
      if (moveX < 0) {
        moveX = 0;
      } else if (moveX > end) {
        moveX = end;
      }
      this.currentCaptchaConfig.moveX = moveX;
      this.currentCaptchaConfig.movePercent = moveX / bgImageWidth;
      this.doMove(this.currentCaptchaConfig);
    },
    up(event) {
      window.removeEventListener("mousemove", this.move);
      window.removeEventListener("mouseup", this.up);
      window.removeEventListener("touchmove", this.move);
      window.removeEventListener("touchend", this.up);
      if (event instanceof TouchEvent) {
        event = event.changedTouches[0];
      }
      this.currentCaptchaConfig.stopTime = new Date();
      let pageX = Math.round(event.pageX);
      let pageY = Math.round(event.pageY);
      const startX = this.currentCaptchaConfig.startX;
      const startY = this.currentCaptchaConfig.startY;
      const startTime = this.currentCaptchaConfig.startTime;
      const trackArr = this.currentCaptchaConfig.trackArr;

      const track = {
        x: pageX - startX,
        y: pageY - startY,
        type: "up",
        t: (new Date().getTime() - startTime.getTime())
      }

      trackArr.push(track);
      this.valid(this.currentCaptchaConfig);
    },

    loadImg() {
      if (this.img_p2 !== '') {
        let width1 = this.$refs['bg_img'].offsetWidth;
        let height1 = this.$refs['bg_img'].offsetHeight;
        let width2 = this.$refs['slider_img'].offsetWidth;
        let height2 = this.$refs['slider_img'].offsetHeight;
        this.initConfig(width1, height1, width2, height2, 206);
      }
    },
    loadBgImg() {
      if (this.img_p1 !== '') {
        let width1 = this.$refs['bg_img'].offsetWidth;
        let height1 = this.$refs['bg_img'].offsetHeight;
        let width2 = this.$refs['slider_img'].offsetWidth;
        let height2 = this.$refs['slider_img'].offsetHeight;
        this.initConfig(width1, height1, width2, height2, 206);
      }
    },
    handleCancel() {
      this.visibleModal = false;
    },
    //获取验证码
    getSmscode() {
      if (this.userData.contactPhone === '') {
        this.$message.warning("请输入联系人手机号码！");
        return;
      }
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!reg.test(this.userData.contactPhone)) {
        this.$message.warning("请输入正确的电话号码！");
        return;
      }
      this.visibleModal = true;
      this.refreshCaptcha();
    },
    reloadImg() {
      this.refreshCaptcha();
    },
  },
  created() {
    this.id = this.$route.query.id;
    // this.getTalentData();
    this.getRecordData();
  },
}
</script>

<style scoped>
.headerbox {
  padding: 20px;
  margin: 20px auto;
  width: 1200px;
  border-radius: 30px;
  background-color: #FFFFFF;
}
.contentbox {
  padding: 20px;
  margin: 20px auto;
  width: 1200px;
  border-radius: 30px;
  background-color: #FFFFFF;
}
.contbox {
  padding: 20px;
  margin-left: 200px;
  margin-right: 200px;
  margin-bottom: 20px;
  border-radius: 30px;
}
.spantitle {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
}
.spanstatus1 {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  border-radius: 5px;
  background-color: #497BFD;
  color: #FFFFFF;
}
.spanstatus2 {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  border-radius: 5px;
  background-color: #FDB549;
  color: #FFFFFF;
}
.spanslast {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}
.pteacher {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.p1 {
  margin: 0px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
  background-color: #FF7078;
  color: #FFFFFF;
  width: 400px;
}
.p2 {
  margin: 0px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #f0f0f0;
  color: #FF434E;
  width: 400px;
}
.spanpay {
  color: #FF434E;
  text-align: left;
  width: 120px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
}
.spanpayt {
  margin-left: 100px;
  color: #909399;
  width: 280px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}
.btn {
  width: 120px;
  background-color: #00CF97;
  color: #FFFFFF;
}
.slider {
  background-color: #fff;
  width: 278px;
  height: 220px;
  z-index: 999;
  box-sizing: border-box;
  padding: 9px;
  border-radius: 6px;
  box-shadow: 0 0 11px 0 #999999;
}

.slider .content {
  width: 100%;
  height: 159px;
  position: relative;
}

.bg-img-div {
  width: 100%;
  height: 100%;
  position: absolute;
  transform: translate(0px, 0px);
}

.slider-img-div {
  height: 100%;
  position: absolute;
}

.bg-img-div img {
  width: 100%;
}

.slider-img-div img {
  height: 100%;
}

.slider .slider-move {
  height: 60px;
  width: 100%;
  margin: 11px 0;
  position: relative;
}

.slider .bottom {
  height: 19px;
  width: 100%;
}

.refresh-btn,
.close-btn,
.slider-move-track {
  background: url(https://static.geetest.com/static/ant/sprite.1.2.4.png) no-repeat;
}

.slider-move-btn {
  background: url(https://ivs.jd.com/slide/i/jd-slide-btn2.png) no-repeat;
}

.slider-move .slider-move-track {
  line-height: 38px;
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
  color: #88949d;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.slider {
  user-select: none;
}

.slider-move {
  transform: translate(0px, 0px);
  /*background-position: -5px 11.79625%;*/
  position: absolute;
  top: -12px;
  left: 0;
  width: 66px;
  height: 66px;
}

.slider-move-btn {
  position: absolute;
  top: -12px;
  left: 0;
  width: 66px;
  height: 66px;
}

.slider-move-btn:hover,
.close-btn:hover,
.refresh-btn:hover {
  cursor: pointer
}
</style>